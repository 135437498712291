import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import logo from "/src/images/iso_color.png";
import pt from "../images/manuals/manual-a4-pt.pdf";
import en from "../images/manuals/manual-a4-en.pdf";
import es from "../images/manuals/manual-a4-es.pdf";
import de from "../images/manuals/manual-a4-de.pdf";
import NewGenerationEn from "../images/manuals/New Generation MicroREC manual.pdf";
import NewGenerationDe from "../images/manuals/DE MicroREC Manual .pdf";
import NewGenerationEs from "../images/manuals/ES - MicroREC Manual .pdf";
import AdapterEn from "../images/manuals/Adapter Manual.pdf";
import BeamSplitterEn from "../images/manuals/Beam Splitter Manual.pdf";
import optirec from "../images/manuals/OptiREC manual single paged (1).pdf";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Manuals = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Manuals | Custom Surgical" />
      <div className="manuals">
        <h1
          style={{
            fontSize: "48px",
            fontWeight: "700",
            color: "white",
            textShadow: "#000 1px 0 10px",
          }}
        >
          Download your manual
        </h1>
      </div>{" "}
      <div
        style={{
          maxWidth: "1200px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <p
          style={{
            marginBottom: "5em",
            fontSize: "20px",
            fontWeight: "600",
            padding: "0px 20px",
          }}
        >
          We appreciate it that you choose our products. Product manuals for the
          MicroREC are available in English, German, Portuguese, and Spanish.
          Please, download them below.
        </p>

        <div
          className="container3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <div style={{ width: "30%" }}>
            <h3 style={{ marginTop: "0" }}>MicroREC</h3>
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ENGLISH</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={NewGenerationEn}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>DEUTSCH</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={NewGenerationDe}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3em",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ESPAÑOL</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={NewGenerationEs}
                  target="_blank"
                >
                  User Manual ▸
                </a>
                {/* <i>(soon)</i> */}
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>PORTUGUÊS</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <div style={{ width: "30%" }}>
            <h3 style={{ marginTop: "0" }}>Adapter</h3>
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ENGLISH</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={AdapterEn}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>DEUTSCH</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3em",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ESPAÑOL</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>PORTUGUÊS</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <div style={{ width: "30%" }}>
            <h3 style={{ marginTop: "0" }}>Beam Splitter</h3>
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ENGLISH</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={BeamSplitterEn}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>DEUTSCH</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3em",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ESPAÑOL</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>PORTUGUÊS</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <div style={{ width: "30%" }}>
            <h3 style={{ marginTop: "0" }}>Previous MicroREC</h3>
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ENGLISH</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={en}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>DEUTSCH</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={de}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3em",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ESPAÑOL</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={es}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>PORTUGUÊS</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={pt}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px",
          }}
        >
          <div style={{ width: "30%" }}>
            <h3 style={{ marginTop: "0" }}>OptiREC</h3>
          </div>
          <div
            style={{
              width: "30%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              className="container3 reveal fade-left"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ENGLISH</h4>
                <hr></hr>
                <a
                  className="links_post"
                  style={{ fontSize: "20px" }}
                  href={optirec}
                  target="_blank"
                >
                  User Manual ▸
                </a>
              </div>
            </div>

            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>DEUTSCH</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>

            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>ESPAÑOL</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>

            <div
              className="container3 reveal fade-left"
              style={{
                marginTop: "3em",
                marginBottom: "3em",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div style={{ marginBottom: "5em" }}>
                <h4 style={{ marginTop: "0", fontWeight: "500" }}>PORTUGUÊS</h4>
                <hr></hr>

                <i>(soon)</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Manuals;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
